// Tracking methods: Omniture, GA(Google Analytics), Spotlight, Floodlight
// 02-11-2010

// prettier-ignore
(function() {
    var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
    ga.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'stats.g.doubleclick.net/dc.js';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
})();

// Google Analytics
var _gaq = _gaq || [];

// Google Tag Manager
var dataLayer = dataLayer || [];

// GA4
var load_ga4_script = function (analyticsIdString) {
    /* Load the GA4 gtag.js script using a given analytics ID string */
    let ga4 = document.createElement("script");
    ga4.type = "text/javascript";
    ga4.async = true;
    let ga4_param = "?" + analyticsIdString;
    let http_protocol = "https:" == document.location.protocol ? "https://" : "http://";
    let ga4_url = "www.googletagmanager.com/gtag.js";
    ga4.src = http_protocol + ga4_url + ga4_param;
    let _s = document.getElementsByTagName("script")[0];
    _s.parentNode.insertBefore(ga4, _s);
};

var setCookieDomainAndTrackingID = function () {
    /* Default value is the DEV tracking ID */
    let _bexCookieDomain = window.location.hostname;
    let _analyticsTrackingID = "G-0G7NHV7RDB";
    if (window.location.href.match(/becomeanex\.org/)) {
        _bexCookieDomain = ".becomeanex.org";
        _analyticsTrackingID = "G-S9KFPXFN74";
    }
    return [_bexCookieDomain, _analyticsTrackingID];
};

const [bexCookieDomain, analyticsTrackingID] = setCookieDomainAndTrackingID();
load_ga4_script(analyticsTrackingID);

window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", analyticsTrackingID);

// End GA4 code

var mm_ref = window.location.search.match(/mm_ref=([^&]+)/);
if (mm_ref) {
    _gaq.push(["_setReferrerOverride", decodeURIComponent(mm_ref[1])]);
} else if (window.location.href.match(/m\.becomeanex\.org/)) {
    _gaq.push(["_addIgnoredRef", "www.becomeanex.org"]);
}
_gaq.push(["_setAllowHash", false]);
_gaq.push(["_trackPageview"]);

var BEXTracker = new (function () {
    var cachedIsDev = null;

    var pathName = window.location.pathname;
    var isCommunity = window.location.host.match(/(community|legacyelggupgrade)/i) && 1;

    this.CookieDomain = bexCookieDomain;

    var mm_urid = "";
    var uridMatch = window.location.search.match(/[?&]mm_urid=([^&]+)/);
    if (uridMatch) {
        document.cookie = "MMURID=" + uridMatch[1];
        mm_urid = uridMatch[1];
    } else if ((uridMatch = document.cookie.match(/MMURID=([^;]+)/))) {
        mm_urid = uridMatch[1];
    }

    var campaignMatch = window.location.search.match(/[?&](pid|cid)=([^&]+)/);
    /* The EXIFrameCampaign can be used for Facebook cid tracking */
    if (!campaignMatch && !document.cookie.match(/EXCampaign=/)) {
        campaignMatch = document.cookie.match(/EX(IFrameCampaign)=([^;]*)/);
    }
    if (campaignMatch) {
        var expiry = new Date();
        expiry.setFullYear(expiry.getFullYear() + 10);
        document.cookie =
            "EXCampaign=" +
            campaignMatch[1] +
            "~" +
            campaignMatch[2] +
            "~" +
            new Date().getTime() +
            "; expires=" +
            expiry +
            "; domain=" +
            this.CookieDomain;
    }

    this.getCampaign = function () {
        var campaignMatch = document.cookie.match(/EXCampaign=([^;]+)/);
        if (!campaignMatch) {
            return {
                campaignType: false,
                campaign: false,
                dateAdded: false,
                age: 0,
            };
        }
        var campaignData = campaignMatch[1].split("~");
        return {
            campaignType: campaignData[0],
            campaign: campaignData[1],
            dateAdded: new Date(parseInt(campaignData[2], 10)),
            age: new Date().getTime() - parseInt(campaignData[2], 10),
        };
    };

    /* DoubleClick tags and other pixels */
    this.pageSpecificAnalytics = function () {};

    this.console = function (text) {
        if (typeof console == "object" && typeof console.log == "function" && document.DEBUG) {
            console.log(text);
        }
        return;
    };

    // / DOC READY EVENT
    $(document).ready(function () {
        try {
            if (BEXTracker.isDev()) {
                BEXTracker.console("DEV: Initializing scripts");
                return;
            }
            BEXTracker.pageSpecificAnalytics();
            BEXTracker.addAddThisTracking();
            BEXTracker.trackNavLinks();
            BEXTracker.captureSource(); // capture incoming source code
            BEXTracker.addLinkerEvents();
        } catch (err) {}
    });

    // returns a personID if currently logged in, returns true if registered but logged out, returns false if we don't think they were ever registered
    this.isRegistered = function () {
        if (document.PersonID) {
            return document.PersonID;
        }
        if (window.s && s.prop23) {
            return s.prop23; // this is a fallback, not sure why this would be set but PersonID would not
        }
        if (document.cookie.match(/EXRegistered=/)) {
            return true; // user is registered, but no longer logged in
        }
        return false;
    };

    this.trackCustomRegistration = function (signup_source, selected_payer) {
        if (window.s && signup_source && selected_payer) {
            s.eVar51 = signup_source;
            s.prop49 = signup_source;
            s.eVar52 = selected_payer;
            s.prop52 = selected_payer;
            s.tl(this, "o", "Custom Signup");
        }
    };
    /**
     * Used for manually setting the personID after page load.
     *
     * Really this is only applicable on registration because that's the
     * only page where someone starts using the page without a personID,
     * and then has a personID after using it. For all other
     * pages, s_code should set the personID based on data sent from the
     * backend.
     */
    this.trackPersonID = function (personID) {
        if (window.s && personID) {
            s.eVar23 = personID;
            s.prop23 = personID;
        }
    };

    // track header and footer links, by adding a hash that we will look for later
    this.trackNavLinks = function () {
        $("#topNav a, .searchAndButtons a, #header h1 a").each(function () {
            var $this = $(this);
            $this.attr("href", $this.attr("href") + "#thl");
        });
        $("#bottomNav a").each(function () {
            var $this = $(this);
            $this.attr("href", $this.attr("href") + "#tfl");
        });
    };

    // utility used throughout for language-specific analytics
    this.isSpanish = function () {
        if (document.BEXlanguage !== undefined && document.BEXlanguage.match(/es/i)) {
            return true;
        } else {
            return false;
        }
    };

    this.isDev = function () {
        return false; // no longer needed
    };

    this.addAddThisTracking = function () {
        if (typeof addthis !== "undefined") {
            if (typeof window.attachedAddThisOmnitureListener === "undefined") {
                window.attachedAddThisOmnitureListener = true;
                addthis.addEventListener("addthis.menu.share", function (evt) {
                    if (window.s && evt.type == "addthis.menu.share") {
                        s.linkTrackVars = "action,events";
                        s.linkTrackEvents = "event51";
                        s.events = "event51";
                        s.action = evt.data.service;
                        s.tl(this, "o", "Share:" + evt.data.service + ":" + evt.data.url);
                        s.events = "";
                        s.linkTrackEvents = "";
                    }
                });
            }
        }
    };

    this.getOmnitureCampaign = function () {
        var s_sess = document.cookie.match(/s_sess=([^;]+)/);
        if (!s_sess) {
            return false;
        }

        var s_campaign = unescape(s_sess).match(/s_campaign=([^;]+);/);
        if (!s_campaign) {
            return false;
        }

        return s_campaign[1];
    };

    this.hasCampaignURLTokens = function () {
        var searchParams = document.location.search;
        var hasCidOrPid = searchParams.match(/[CcPp][Ii][dD]=/);
        if (!hasCidOrPid) {
            return false;
        }
        return true;
    };
    // ///////////////////////////////////////////////////////////////////////////
    // / SYSTEM EVENTS
    // ///////////////////////////////////////////////////////////////////////////

    this.Registration_ChangePage = function (page) {
        this.statTrack("event", "registration", "go-to-step-" + page);
        dataLayer.push({ event: "register_step_" + page });

        if (window.s) {
            s.events = "event18,event20";
            s.pageName = window.document.BEXlanguage + ":create-profile:step-" + page;
            s.t();
        }
    };

    this.Registration_Complete = function (isSmoker) {
        // event3 - combined registration complete
        // event4 - non-smoker registration complete
        // event45 - smoker registration complete
        var eventName = "event3";
        if (isSmoker === false) {
            eventName += ",event4";
        } else if (isSmoker) {
            eventName += ",event45";
        }
        // If empty string from 2023 reg form, don't add other events

        // Clear basic information about the page.
        if (window.s) {
            s.pageName = window.document.BEXlanguage + ":create-profile:complete";
            s.events = eventName;
            s.t();
        }
        this.statTrack("virtpage", "/reg-complete");
        this.statTrack("event", "registration", "complete");
        dataLayer.push({ event: "register" });
    };

    /**
     * Submit was clicked, but before submission to local server.
     * We are sending event ??? to OMNITURE, and its value is {success|failure}:{list of failure conditions}
     * @param bool bIsSuccess      - was validation successful?
     * @param array aErrorMessages - array of simple error keys
     */
    this.Registration_SubmitClick = function (bIsSuccess, pageValidationErrors) {
        var sTrackVal;
        if (bIsSuccess) {
            sTrackVal = "success";
        } else if (!bIsSuccess && pageValidationErrors) {
            sTrackVal = "failure:" + pageValidationErrors.errorCodes.join(",");
        } else {
            sTrackVal = "failure:";
        }
        if (window.s) {
            s.linkTrackVars = "eVar2";
            s.eVar2 = sTrackVal;
            if (pageValidationErrors && "username" in pageValidationErrors.errorValues) {
                s.linkTrackVars += ",eVar48";
                s.eVar48 = pageValidationErrors.errorValues["username"];
            }
            s.tl(true, "o", sTrackVal);
        }
    };

    this.Registration_FieldFocused = function (fieldName, fieldVal) {
        var sTrackString = fieldName;
        if (fieldVal != null) {
            sTrackString = fieldName + "-" + fieldVal;
        }
        this.statTrack("event", "registration", "focused", sTrackString);
    };

    this.EditProfileSubmit = function () {
        this.statTrack("event", "edit-profile", "successful");
        dataLayer.push({ event: "edit-profile" });

        if (window.s) {
            s.clearVars("pageName,channel,server,prop1,prop2", 2);
            var eventName = "event5";
            s.events = eventName;
            s.t();
        }
    };

    this.CalendarTracker_Navigator = function (direction) {
        this.statTrack("event", "cig tracker", "calendar nav", direction);
    };
    this.CalendarTracker_SwitchMode = function (context) {
        this.statTrack("event", "cig tracker", "switch mode", context);
    };
    this.CalendarTracker_EnterQuitDateMode = function () {
        this.statTrack("event", "cig tracker", "enter quit mode");
    };
    this.CalendarTracker_LeaveQuitDateMode = function () {
        this.statTrack("event", "cig tracker", "leave quit mode");
    };
    this.CalendarTracker_SaveData = function () {
        this.statTrack("event", "cig tracker", "save data");
    };
    this.CalendarTracker_AddEventRows = function () {
        this.statTrack("event", "cig tracker", "add event rows");
    };
    this.CalendarTracker_EditEventRow = function () {
        this.statTrack("event", "cig tracker", "edit event row");
    };
    // type = Past | TooEarly | Recommended | Future
    this.CalendarTracker_TrackQuitDateSet = function (type) {
        dataLayer.push({ event: "set_quit_date" });
        this.trackEvent("SetQuitDate:" + type);
        this.trackOmnitureEvent("event13");
    };

    this.Alcohol_ProsAndConsFormSubmit = function (SumOfPros, SumOfCons) {
        //applied
        // Capture the sum of all pros and the sum of all cons when user submits the form.
        // Data will be seperated out via classifications (make sure to use the term pros or cons in the captured data so they can be seperated).
        // Also, if form is 100% complete when submitted, capture "yes." For an incomplete form, capture "no."
        // Capture the date the form was submitted.
        // per Gabi: s.prop52="5 pros" and s.prop52="MM/DD/YYYY"
        //applied
        if (window.s && typeof SumOfPros !== "undefined" && typeof SumOfCons !== "undefined") {
            var date = new Date().toISOString().substring(0, 10);
            s.prop52 = SumOfPros + " pros|" + date + "|yes";
            s.eVar52 = SumOfPros + " pros|" + date + "|yes";
            s.tl();
        }
    };

    this.Alcohol_MakeAPlanFormSubmit = function (
        AvoidDrinkingFor,
        AlcoholLimit,
        UserText,
        StrategyCount
    ) {
        //applied
        // Capture the changed ruler input and the sum of all the reasons checked.
        // Classifications will be used to separate this data into separate reports.
        // Also, if form is 100% complete when submitted, capture "yes." For an incomplete form, capture "no."
        // Capture the date the form was submitted.
        // per Gabi: s.prop53="5 reasons" and s.prop53="MM/DD/YYYY"
        if (
            window.s &&
            typeof AvoidDrinkingFor !== "undefined" &&
            typeof AlcoholLimit !== "undefined" &&
            typeof UserText !== "undefined" &&
            typeof StrategyCount !== "undefined"
        ) {
            var date = new Date().toISOString().substring(0, 10);
            /*s.prop53 = 'avoid drinking for '+AvoidDrinkingFor+'|limit to '+AlcoholLimit+' drinks|avoid drinking: '+UserText+'|'+StrategyCount+' strategies|'+(bComplete?'yes':'no')+'|'+date;
			s.eVar53 = 'avoid drinking for '+AvoidDrinkingFor+'|limit to '+AlcoholLimit+' drinks|avoid drinking: '+UserText+'|'+StrategyCount+' strategies|'+(bComplete?'yes':'no')+'|'+date;*/
            s.prop53 =
                "" +
                AvoidDrinkingFor +
                "|" +
                AlcoholLimit +
                "|" +
                UserText +
                "|" +
                StrategyCount +
                "|yes|" +
                date;
            s.eVar53 =
                "" +
                AvoidDrinkingFor +
                "|" +
                AlcoholLimit +
                "|" +
                UserText +
                "|" +
                StrategyCount +
                "|yes|" +
                date;
            s.tl();
        }
    };

    this.Alcohol_StratigiesFormSubmit = function (NoOfStrategies) {
        //applied
        // Capture the sum of all strategies checked.
        // Also, if form is 100% complete when submitted, capture "yes." For an incomplete form, capture "no."
        // Capture the date the form was submitted.
        // per Gabi: s.prop54="5 strategies" and s.prop54="MM/DD/YYYY"
        if (window.s && typeof NoOfStrategies !== "undefined") {
            var date = new Date().toISOString().substring(0, 10);
            s.prop54 = NoOfStrategies + " strategies|" + date + "|yes";
            s.eVar54 = NoOfStrategies + " strategies|" + date + "|yes";
            s.tl();
        }
    };

    this.Alcohol_AllFormsComplete = function (Sum) {
        // If all the above forms are submitted as complete (yes), sum all interactions from each form.
        // Capture the date the form was submitted.
        // per Gabi: s.prop55="10" or s.prop55="MM/DD/YYYY"
        if (window.s && typeof NoOfStrategies !== "undefined") {
            var date = new Date().toISOString().substring(0, 10);
            s.prop55 = Sum + "|" + date;
            s.eVar55 = Sum + "|" + date;
            s.tl();
        }
    };

    this.trackPromotionalCaliforniaOverlay = function (eventName) {
        var label = "Geo_based_partner_upsell:Blue_Shield_of_California";

        if (BEXTracker.isDev()) {
            BEXTracker.console("DEV: Promo_CaliforniaOverlay: " + eventName);
            return false;
        }
        _gaq.push(["_trackEvent", "Overlay", label]);

        if (window.s) {
            s.linkTrackVars = "eVar11";
            s.eVar11 = label;
            s.events = eventName;
            s.tl();
        }
    };

    this.trackOmnitureEvent = function (eventName, pageName) {
        if (window.s) {
            s.clearVars(
                "pageName,channel,server,prop1,prop2,prop22,prop35,eVar35,prop23,eVar23",
                2
            );
            s.events = eventName;
            if (pageName) {
                s.pageName = pageName;
            }
            s.t();
        }
    };

    this.track3StepPlan = function (videoNumber) {
        document.cookie = "track3Step" + videoNumber + "=1; path=/";
        if (
            document.cookie.match(/track3Step1=1/) &&
            document.cookie.match(/track3Step2=1/) &&
            document.cookie.match(/track3Step3=1/)
        ) {
            this.trackOmnitureEvent("event12");
            document.cookie = "track3Step1=0; path=/";
            document.cookie = "track3Step2=0; path=/";
            document.cookie = "track3Step3=0; path=/";
        }
    };

    this.trackAcquisition = function (label, link) {
        _gaq.push(["_trackEvent", "Acquisition", label]);
        if (!link) {
            link = true;
        }
        if (window.s) {
            s.linkTrackVars = "eVar8";
            s.eVar8 = label;
            s.tl(link, "o", label);
        }
    };

    /**
     * Tracks highfive/likes from the community pages
     * @param  {[type]} label     [description]
     * @param  {[type]} link      [description]
     * @param  {[type]} eventName [description]
     * @return {[type]}           [description]
     */
    this.trackSingleEventsCommunity = function (label, link, eventName) {
        _gaq.push(["_trackEvent", "Acquisition", label]);
    };

    this.trackHPLink = function (label, link) {
        _gaq.push(["_trackEvent", "HPLink", label]);
        if (!link) {
            link = true;
        }
        if (window.s) {
            s.linkTrackVars = "eVar9";
            s.eVar9 = label;
            s.tl(link, "o", "HPLink:" + label);
        }
    };

    // EXON-2683 video tracking
    this.trackVideoByName = function (sVideoName) {
        // also get the page to help us, if we need it? (more secure than tracking by name)
        var sPage = document.location.pathname;
        var sEvent = "";

        if (sPage == "/nicotine-addiction/") {
            // Watch Addiction Video
            sEvent = "event10";
        } else if (sPage == "") {
            // Watch 3 step plan
            sEvent = "";
        } else if (sPage == "/") {
            // Watch Homepage Video
            sEvent = "event47";
        }
        //		console.log("Tracking Video "+sVideoName);
        if (window.s && sEvent != "") {
            s.events = sEvent;
            s.t();
        }
    };

    this.trackPromo = function (panel, promoName, link) {
        if (BEXTracker.isDev()) {
            BEXTracker.console('DEV: trackPromo: "Promos","' + panel + '","' + promoName + '"');
            return;
        }
        _gaq.push(["_trackEvent", "Promos", panel, promoName]);
        if (!link) {
            link = true;
        }
        if (window.s) {
            s.linkTrackVars = "eVar10";
            s.eVar10 = panel + ":" + promoName;
            s.tl(link, "o", "Promos:" + panel + ":" + promoName);
        }
    };

    this.trackOverlayLink = function (label, link) {
        if (BEXTracker.isDev()) {
            BEXTracker.console("DEV: trackOverlay: " + label);
            return false;
        }
        _gaq.push(["_trackEvent", "Overlay", label]);
        if (!link) {
            link = true;
        }
        if (window.s) {
            s.linkTrackVars = "eVar11";
            s.eVar11 = label;
            s.tl(link, "o", "Overlay:" + label);
        }
    };

    // ///////////////////////////////////////////////////////////////////////////
    // / HELPER METHODS
    // ///////////////////////////////////////////////////////////////////////////
    // Marissa's method to track virtual pages (GA)
    this.statTrack = function (type, category, label, value) {
        if (BEXTracker.isDev()) {
            BEXTracker.console(
                'DEV: statTrack: "' + type + '","' + category + '","' + label + '","' + value + '"'
            );
            return;
        }
        try {
            switch (type) {
                case "event":
                    _gaq.push(["_trackEvent", category, label, value]);
                    break;
                case "virtpage":
                    _gaq.push(["_trackPageview", category]);
                    break;
            }
        } catch (err) {}
    };

    this.trackEvent = function (page) {
        if (BEXTracker.isDev()) {
            BEXTracker.console('DEV: trackEvent: "' + page + '"');
            return;
        }
        try {
            _gaq.push(["_trackPageview", page]);
            if (window.s) {
                s.tl(true, "o", "Event:" + page);
            }
        } catch (err) {}
    };

    this.flood = function (href, unique) {
        this.invokeFloodlightTag(href, unique);
    };

    this.invokeFloodlightTag = function (href, unique) {
        if (BEXTracker.isDev()) {
            return;
        }
        var axel, num;
        if (unique) {
            num = 1;
        } else {
            axel = Math.random() + "";
            num = axel * 1000000000000000000;
        }
        if (href.match(/ord=$/)) {
            href += "1;num=" + num;
        } else {
            href += ";num=" + num;
        }
        if (!href.match(/\?/)) {
            href += "?";
        }
        this.docWriteIframe(href);
    };

    // href param is without the num part!
    // href should end on "ord=1"
    this.invokeSpotlightTag = function (href) {
        if (BEXTracker.isDev()) {
            return;
        }
        var axel = Math.random() + "";
        var num = axel * 1000000000000000000;
        this.docWriteImage(href + ";num=" + num);
    };

    this.docWriteScript = function (href) {
        if (BEXTracker.isDev()) {
            return;
        }
        $(document.body).append("<scr" + "ipt src='" + href + "'></scri" + "pt>");
    };

    this.docWriteIframe = function (href) {
        if (BEXTracker.isDev()) {
            return;
        }

        tag_url = document.createElement("iframe");
        tag_url.className += " doubleclick tracking";
        tag_url.src = href;
        document.body.appendChild(tag_url);
    };

    this.docWriteImage = function (href) {
        if (BEXTracker.isDev()) {
            return;
        }
        var tag_url = new Image();
        tag_url.src = href;
    };

    // Only links written to the page (already in the DOM) will be tagged
    // This version is for ga.js (last updated Jan 15th 2009)
    this.addLinkerEvents = function () {
        /***********************************************************************
         * Author: Brian J Clifton Url:
         * http://www.advanced-web-metrics.com/scripts This script is free to
         * use as long as this info is left in
         *
         * Combined script for tracking external links, file downloads and
         * mailto links
         *
         * All scripts presented have been tested and validated by the author
         * and are believed to be correct as of the date of publication or
         * posting. The Google Analytics software on which they depend is
         * subject to change, however; and therefore no warranty is expressed or
         * implied that they will work as described in the future. Always check
         * the most current Google Analytics documentation.
         *
         * Thanks to Nick Mikailovski (Google) for intitial discussions & Holger
         * Tempel from webalytics.de for pointing out the original flaw of doing
         * this in IE.
         *
         * Modified by Beaconfire Consulting 10/14/2009 to add pagelinkers and
         * other customizations
         **********************************************************************/
        var as = document.getElementsByTagName("a");
        var extTrack = [
            "becomeanex.org",
            "www.becomeanex.org",
            "es.becomeanex.org",
            "m.becomeanex.org",
            "m.wap.becomeanex.org",
            "excommunity.becomeanex.org",
            "m.community.becomeanex.org",
        ];

        // Add current hostname to list so it isn't considered an external url
        // This will handle cases like uat feature boxes and local instances
        var hostname = window.location.hostname;
        extTrack.push(hostname);

        var extDoc = [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".zip"];

        /*
         * If you edit no further below this line, Top Content will report as
         * follows: /ext/url-of-external-site /downloads/filename
         * /mailto/email-address-clicked
         */

        for (var i = 0; i < as.length; i++) {
            var flag = 0;
            var tmp = as[i].getAttribute("onclick");

            // Skip anchors with empty href
            if (!as[i].getAttribute("href")) {
                continue;
            }

            // Skip anchor links
            if (as[i].getAttribute("href").match(/^#/)) {
                continue;
            }

            // IE6-IE7 fix (null values error) with thanks to Julien Bissonnette
            // for this
            if (tmp != null) {
                tmp = String(tmp);
                if (tmp.indexOf("urchinTracker") > -1 || tmp.indexOf("_trackPageview") > -1)
                    continue;
            } else {
                // Tracking outbound links off site
                for (var j = 0; j < extTrack.length; j++) {
                    if (
                        as[i].href.indexOf(extTrack[j]) == -1 &&
                        as[i].href.indexOf("google-analytics.com") == -1
                    ) {
                        flag++;
                    } else if (document.location.href.indexOf(extTrack[j]) == -1) {
                        // off-site link, but track as an internal link
                        /* Not necessary since all cross-site links are in cookie scope
						as[i].setAttribute("onclick",
								"_gaq.push(['_link',this.href]);"
										+ ((tmp != null) ? tmp + ";" : "")
										+ "return false;");
						*/
                    }
                }
                if (flag == extTrack.length && as[i].href.indexOf("mailto:") == -1) {
                    as[i].setAttribute(
                        "onclick",
                        "_gaq.push([ '_trackPageview', '/external/' + this.href ]);" +
                            (tmp != null ? tmp + ";" : "")
                    );
                }
                // Tracking electronic documents - doc, xls, pdf, exe, zip
                for (var j = 0; j < extDoc.length; j++) {
                    if (as[i].href.indexOf(extDoc[j]) != -1) {
                        as[i].setAttribute(
                            "onclick",
                            "_gaq.push(['_trackPageview', '/docs/' + this.href ]);" +
                                (tmp != null ? tmp + ";" : "")
                        );
                        break;
                    }
                }
                // added to track mailto links 23-Oct-2007
                // updated 31-Oct-2008 to remove break command - thanks to
                // Victor Geerdink for spotting this
                if (as[i].href.indexOf("mailto:") != -1) {
                    as[i].setAttribute(
                        "onclick",
                        "var splitResult = this.href.split(':'); _gaq.push(['_trackPageview', '/mailto/' + splitResult[1] ]);" +
                            (tmp != null ? tmp + ";" : "")
                    );
                }
            }
        }
    };

    this.captureSource = function () {
        var sc = BEXTracker.getURLParam("sourcecode");
        if (sc != "") {
            var dateObj = new Date();
            dateObj.setTime(dateObj.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
            var options = {
                path: "/",
                expires: dateObj,
                domain: document.domain.substring(document.domain.indexOf(".") + 1),
            };
            $.cookie("sourcecode", sc, options);
        }
    };

    this.getURLParam = function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(window.location.href);
        if (results == null) {
            return "";
        } else {
            return results[1];
        }
    };

    // EXON-2851 - attached to button on bex-and-the-aca.php page, attaching triggered at the bottom of this file
    this.trackACAContactButton = function (sHref) {
        /* Already happening in addLinkerEvents method above
		var aHrefParts = sHref.split(':');
		if (aHrefParts.length >= 2) {
			var sEmailSubject = aHrefParts[1];
			_gaq.push(['_trackPageview', '/mailto/' + sEmailSubject ]);
		}
		*/
        var sEvent = "event71";
        if (window.s) {
            s.events = sEvent;
            s.t();
        }

        /* Removed per conversation with Sim 3/7/2017, EXON-2899
		var sGoogleFullPage = document.location.protocol + "//" + document.location.hostname + "/" + sHref;
		// this is defined in views/TrackingGlobal.php
		if (goog_report_conversion) {
			goog_report_conversion(sGoogleFullPage);
		}
		*/
    };

    this.trackDismissibleBannerEvent = function (eventName, banner_label) {
        var events_by_name = {
            show_banner: "event77",
            close_banner: "event78",
            click_banner: "event79",
        };
        if (window.s) {
            s.eVar11 = banner_label;
            s.events = events_by_name[eventName];
            s.t();
        }
    };
})();

// Event tracker for old flash pieces
function trackEvent(page) {
    if (BEXTracker.isDev()) {
        return;
    }
    _gaq.push(["_trackEvent", "homepage", page]);
}

// EXON-2851
$(document).ready(function () {
    $("#ACAContactButton").on("click", function () {
        var sHref = $(this).attr("href");
        BEXTracker.trackACAContactButton(sHref);
    });
});
